import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAPI } from "../../api";
import { LiveboardsQueryKey } from "../../hooks/useLiveboards";

type CreateLiveboardArgs = {
  liveboard_name: string;
};

export function useCreateLiveboard() {
  const api = useAPI();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (args: CreateLiveboardArgs) => {
      return api.postThoughtspotLiveboardsCreate({
        liveboard_name: args.liveboard_name,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [LiveboardsQueryKey] });
    },
  });
}
