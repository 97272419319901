export function PackageCheck() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.6666 4.85173L7.99998 7.99987M7.99998 7.99987L2.33331 4.85173M7.99998 7.99987L8 14.3332M9.33333 13.9258L8.51802 14.3788C8.32895 14.4838 8.23442 14.5363 8.1343 14.5569C8.0457 14.5751 7.95431 14.5751 7.8657 14.5569C7.76559 14.5363 7.67105 14.4838 7.48198 14.3788L2.54865 11.638C2.34897 11.5271 2.24912 11.4716 2.17642 11.3927C2.11211 11.3229 2.06343 11.2402 2.03366 11.1501C2 11.0482 2 10.934 2 10.7056V5.29419C2 5.06576 2 4.95155 2.03366 4.84968C2.06343 4.75957 2.11211 4.67684 2.17642 4.60705C2.24912 4.52816 2.34897 4.47269 2.54865 4.36176L7.48198 1.62102C7.67105 1.51598 7.76559 1.46346 7.8657 1.44287C7.95431 1.42464 8.0457 1.42464 8.1343 1.44287C8.23442 1.46346 8.32895 1.51598 8.51802 1.62102L13.4514 4.36176C13.651 4.47269 13.7509 4.52816 13.8236 4.60705C13.8879 4.67684 13.9366 4.75956 13.9663 4.84968C14 4.95155 14 5.06576 14 5.29419L14 8.33323M5 2.99989L11 6.33323M10.6667 11.9999L12 13.3332L14.6667 10.6666"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
