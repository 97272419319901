import { SvgIcon, SvgIconType } from "../../svg";

type SimpleButtonProps = {
  icon: SvgIconType;
  label: string;
  onClick?: () => void;
  isActive?: boolean;
  showChevron?: boolean;
};

export function SimpleButton({
  icon,
  label,
  onClick,
  isActive = false,
  showChevron = false,
}: SimpleButtonProps) {
  return (
    <button
      onClick={onClick}
      type="button"
      title={label}
      className={`
        flex items-center gap-4 font-medium transition-colors py-2 px-4 rounded-lg
        ${
          isActive
            ? "bg-backgroundGray text-dark"
            : "text-feintGray hover:text-dark hover:bg-backgroundGray/50"
        }
      `}
    >
      <SvgIcon icon={icon} />
      <span className="max-w-[200px] truncate">{label}</span>
      {showChevron && <SvgIcon icon="ChevronDown" />}
    </button>
  );
}
