import clsx from "clsx";
import { SvgIcon } from "../../svg";

type CreateLiveboardButtonProps = {
  isExpanded: boolean;
  onClick: () => void;
};

export function CreateLiveboardButton({ isExpanded, onClick }: CreateLiveboardButtonProps) {
  return (
    <button
      className={clsx(
        "relative",
        "cursor-pointer",
        "flex items-center justify-between",
        "pr-4 rounded-lg",
        "overflow-visible",
        "text-feintGray hover:bg-white hover:text-dark",
        isExpanded ? "py-3 pl-4 pr-3 w-full" : "py-3 px-2",
      )}
      onClick={onClick}
    >
      <div className={clsx("flex gap-2 items-center overflow-hidden", "min-w-[20px]")}>
        <div>{<SvgIcon icon="Plus" />}</div>
        {isExpanded && <div className={"truncate font-medium"}>Create Liveboard</div>}
      </div>
    </button>
  );
}
