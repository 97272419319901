import React from "react";
import { useLocation, useNavigate } from "react-router";
import { FavoriteToggle } from "./FavoriteToggle";
import { LiveboardList } from "./LiveboardList";
import { SidebarButton } from "./SidebarButton";
import { CreateLiveboardDialog } from "./CreateLiveboardDialog";
import clsx from "clsx";
import resolveConfig from "tailwindcss/resolveConfig";
// @ts-expect-error it's just a config file
import tailwindConfig from "../../../tailwind.config.js";
import { CreateLiveboardButton } from "./CreateLiveboardButton";

export function Sidebar() {
  const [showFavoritesOnly, setShowFavoritesOnly] = React.useState(false);
  const [isAddLiveboardDialogOpen, setIsAddLiveboardDialogOpen] = React.useState(false);
  const [isExpanded, setIsExpanded] = React.useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  const selectedLiveboardId = location.pathname.match(/\/pinboard\/([^/]+)/)?.[1] || null;

  function onLiveboardSelected(liveboardId: string) {
    navigate(`/pinboard/${liveboardId}`);
  }

  const twConfig = resolveConfig(tailwindConfig);
  const twBreakpoint = twConfig.theme.screens.md ?? "768px";
  const collapseTriggeringWidth = twBreakpoint.match(/^([0-9]+)/)[1] ?? 768;

  React.useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (window.innerWidth < collapseTriggeringWidth) {
        setIsExpanded(false);
      } else if (window.innerWidth >= collapseTriggeringWidth) {
        setIsExpanded(true);
      }
    });

    resizeObserver.observe(document.body);
    return () => {
      resizeObserver.unobserve(document.body);
      resizeObserver.disconnect();
    };
  }, [collapseTriggeringWidth]);

  function userTogglesExpansion() {
    setIsExpanded((curr) => !curr);
  }

  function style(s: Partial<{ expanded: string; collapsed: string; default: string }>): string {
    return clsx(s.default, isExpanded ? s.expanded : s.collapsed);
  }

  return (
    <>
      <aside
        className={style({
          default:
            "relative max-w-[272px] pt-4 pl-4 flex flex-col h-full gap-4 transition-all duration-150",
          expanded: "w-[35%] md:w-[30%]",
          collapsed: "w-[68px]",
        })}
      >
        <div className="absolute top-[50%] -right-6 z-10 flex flex-col gap-2">
          <div className="h-10 w-10">
            <FavoriteToggle
              showFavoritesOnly={showFavoritesOnly}
              setShowFavoritesOnly={setShowFavoritesOnly}
            />
          </div>

          <SidebarButton
            icon="DoubleChevronLeft"
            onClick={userTogglesExpansion}
            rotate={!isExpanded}
          />

          <SidebarButton icon="Plus" onClick={() => setIsAddLiveboardDialogOpen(true)} />
        </div>

        <nav className="sidebar-content-container flex-grow overflow-y-auto overflow-x-hidden text-sm">
          <LiveboardList
            selectedLiveboardId={selectedLiveboardId}
            favoritesOnly={showFavoritesOnly}
            onLiveboardSelected={onLiveboardSelected}
            isSidebarExpanded={isExpanded}
          />
          <CreateLiveboardButton
            isExpanded={isExpanded}
            onClick={() => setIsAddLiveboardDialogOpen(true)}
          />
        </nav>
      </aside>

      <CreateLiveboardDialog
        show={isAddLiveboardDialogOpen}
        onClose={() => setIsAddLiveboardDialogOpen(false)}
      />
    </>
  );
}
