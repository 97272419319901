import clsx from "clsx";
import { SvgIcon, SvgIconType } from "../../svg";

type SidebarButtonProps = {
  icon: SvgIconType;
  onClick: () => void;
  rotate?: boolean;
};

export function SidebarButton({ icon, onClick, rotate = false }: SidebarButtonProps) {
  return (
    <div className="h-10 w-10">
      <button className="h-full w-full" onClick={onClick}>
        <div
          className={clsx(
            "h-full w-full rounded-lg flex items-center justify-center",
            "bg-white text-feintGray hover:text-dark shadow-button",
          )}
        >
          <div className={clsx(rotate && "rotate-180")}>
            <SvgIcon icon={icon} />
          </div>
        </div>
      </button>
    </div>
  );
}
