import { useForm, SubmitHandler } from "react-hook-form";
import { useLogin } from "../../hooks/useLogin";
import { SvgIcon } from "../../svg";
import { Link, Outlet, useLocation, useNavigate } from "react-router";
import { initiateNewFoodicsBusinessInstallation } from "../../api";
import { LoginRequest as LoginDetails } from "@norma-bi/bi-api";
import { isEmailValid, isAccountNumberValid } from "@norma-bi/utils";
import { PulseLoader } from "react-spinners";
import { AlreadyInstalledDialog } from "./AlreadyInstalledDialog";

export function Login() {
  const { login, isPending, isWrongCredentials } = useLogin();
  const navigate = useNavigate();
  const location = useLocation();

  type LoginForm = Omit<LoginDetails, "businessRef"> & { accountNumber: string };

  const { register, handleSubmit, watch } = useForm<LoginForm>({
    defaultValues: { accountNumber: "", email: "", password: "" },
  });

  const watchAccountNumber = watch("accountNumber");
  const watchEmail = watch("email");
  const watchPassword = watch("password");

  const onSubmit: SubmitHandler<LoginForm> = async (data) => {
    const result = await login({
      business_ref: parseInt(data.accountNumber),
      email: data.email,
      password: data.password,
    });
    if (!result) {
      return;
    }
    const redirectAfterLogin: Location = location.state?.redirectAfterLogin;
    if (redirectAfterLogin) {
      navigate(redirectAfterLogin, { replace: true });
    } else {
      navigate("/", { replace: true });
    }
  };

  const canSignIn =
    !isPending &&
    isAccountNumberValid(watchAccountNumber) &&
    isEmailValid(watchEmail) &&
    watchPassword !== "";

  return (
    <>
      <Outlet />
      <div
        className="max-w-none mx-auto h-screen flex justify-center items-center"
        style={{
          background: `
        linear-gradient(to bottom, transparent 50%, white 70%),
        repeating-radial-gradient(circle at center, transparent, transparent 45px, #E1DBE9 45px, #E1DBE9 46px),
        radial-gradient(circle at center, #EAE3F3 20%, #FFFFFF 60%)
      `,
          backgroundSize: "100%, 100%",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="text-neutralContent w-[34%] max-w-[348px]">
          <div className="mx-auto w-[80px] h-[80px] mb-10">
            <SvgIcon icon="CompanyLogoLogin" />
          </div>

          <form className="space-y-3 text-center" onSubmit={handleSubmit(onSubmit)}>
            <div className="text-2xl font-medium text-dark">Foodics BI</div>
            <div className="text-xs text-light">Login with your Credentials</div>

            <div className="pt-8 px-1 w-full">
              <div className="flex justify-between items-center">
                <label className="label text-xs text-dark">Enter Credentials</label>
                <Link to="./forgot-password" className="label text-xs text-dark underline">
                  Reset BI Password
                </Link>
              </div>
            </div>
            <div>
              <input
                {...register("accountNumber")}
                type="number"
                className="thoughtspot-login-input"
                placeholder="Account No"
              />
            </div>
            <div>
              <input
                {...register("email")}
                type="email"
                className="thoughtspot-login-input"
                placeholder="Email"
                autoComplete="email"
              />
            </div>
            <div>
              <input
                {...register("password")}
                type="password"
                className="thoughtspot-login-input"
                placeholder="Password"
                id="current-password"
                autoComplete="current-password"
              />
            </div>
            {isPending && (
              <div className="flex justify-center pt-4">
                <PulseLoader
                  color="lightgray"
                  loading={isPending}
                  size={15}
                  speedMultiplier={0.6}
                />
              </div>
            )}
            <div>
              {isWrongCredentials && <span className="text-errorContent">Wrong credentials</span>}
            </div>
            <div className="flex justify-between pt-8">
              <input
                type="submit"
                disabled={!canSignIn}
                className={`w-full h-9 font-normal rounded-lg text-white text-xs shadow-button bg-primaryColor disabled:bg-primaryColor disabled:bg-opacity-10`}
                value={isPending ? "Signing in..." : "Sign in"}
              />
            </div>
            <div className="flex">
              <button type="button" onClick={initiateNewFoodicsBusinessInstallation}>
                <span className="text-footnote hover:underline">Install</span>
              </button>
            </div>
          </form>
        </div>
      </div>

      <AlreadyInstalledDialog />
    </>
  );
}
