import { Package } from "./DataSourceIcons/Package";
import { Database } from "./DataSourceIcons/Database";
import { Folder } from "./DataSourceIcons/Folder";
import { LayerSingle } from "./DataSourceIcons/LayerSingle";
import { LayersThree } from "./DataSourceIcons/LayersThree";
import { LayersTwo } from "./DataSourceIcons/LayersTwo";
import { PackageCheck } from "./DataSourceIcons/PackageCheck";
import { PackageSearch } from "./DataSourceIcons/PackageSearch";
import { Puzzle } from "./DataSourceIcons/Puzzle";
import { Server } from "./DataSourceIcons/Server";

const iconComponents = {
  Package,
  Database,
  Folder,
  LayerSingle,
  LayersThree,
  LayersTwo,
  PackageCheck,
  PackageSearch,
  Puzzle,
  Server,
} as const;

type IconName = keyof typeof iconComponents;

const icons = Object.keys(iconComponents) as IconName[];

export function selectIcon(seed: string) {
  let hash = 0;
  for (let i = 0; i < seed.length; i++) {
    const char = seed.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }
  const index = Math.abs(hash) % icons.length;
  const iconName = icons[index];
  return iconComponents[iconName];
}
