import { Modal } from "../../components/Modal";
import React from "react";
import { useCreateLiveboard } from "./useCreateLiveboard";
import { useNavigate } from "react-router";
import { customToast } from "../../utils/customToast";

type CreateLiveboardDialogProps = {
  show: boolean;
  onClose: () => void;
};

export function CreateLiveboardDialog({ show, onClose }: CreateLiveboardDialogProps) {
  const [liveboardName, setLiveboardName] = React.useState("");
  const navigate = useNavigate();
  const createLiveboard = useCreateLiveboard();

  function handleCreateLiveboard() {
    createLiveboard.mutate(
      {
        liveboard_name: liveboardName,
      },
      {
        onSuccess: (response) => {
          onClose();
          setLiveboardName("");
          navigate(`/pinboard/${response.id}`);
          customToast("Liveboard created successfully", "success", "bottom");
        },
        onError: (error) => {
          console.error(error);
          customToast("Unexpected error", "error", "bottom");
        },
      },
    );
  }

  return (
    <Modal show={show} onShowChanged={onClose} dialogClassname="overflow-visible rounded-2xl">
      <div className="p-0 shadow-lg flex flex-col items-center w-96 justify-between bg-white rounded-2xl">
        <div className="w-full bg-white flex flex-col gap-2 justify-center items-center text-feintGray text-base rounded-t-2xl">
          <div className="flex flex-col gap-y-4 w-full px-6 pt-6 pb-1">
            <label className="block text-xs text-dark">Liveboard Name</label>
            <input
              type="text"
              value={liveboardName}
              onChange={(e) => setLiveboardName(e.target.value)}
              className="w-full px-3 py-2 border border-light rounded-md focus:outline-none"
              placeholder="Enter liveboard name"
            />
          </div>
        </div>
        <div className="w-full flex justify-end gap-2 px-6 pb-6 pt-7 bg-white rounded-b-2xl">
          <button
            onClick={handleCreateLiveboard}
            disabled={!liveboardName.trim() || createLiveboard.isPending}
            className="w-full px-4 py-2 text-sm font-medium text-white bg-primaryColor border border-light rounded-lg hover:bg-primaryColorDark focus:outline-none focus:ring-primaryColor disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Create
          </button>
        </div>
      </div>
    </Modal>
  );
}
