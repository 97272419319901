export function Puzzle() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3112_11949)">
        <path
          d="M5.00016 3.00016C5.00016 2.07969 5.74636 1.3335 6.66683 1.3335C7.5873 1.3335 8.3335 2.07969 8.3335 3.00016V4.00016H9.00016C9.93205 4.00016 10.398 4.00016 10.7655 4.1524C11.2556 4.35539 11.6449 4.74474 11.8479 5.2348C12.0002 5.60234 12.0002 6.06828 12.0002 7.00016H13.0002C13.9206 7.00016 14.6668 7.74636 14.6668 8.66683C14.6668 9.5873 13.9206 10.3335 13.0002 10.3335H12.0002V11.4668C12.0002 12.5869 12.0002 13.147 11.7822 13.5748C11.5904 13.9511 11.2845 14.2571 10.9081 14.4488C10.4803 14.6668 9.92027 14.6668 8.80016 14.6668H8.3335V13.5002C8.3335 12.6717 7.66192 12.0002 6.8335 12.0002C6.00507 12.0002 5.3335 12.6717 5.3335 13.5002V14.6668H4.5335C3.41339 14.6668 2.85334 14.6668 2.42552 14.4488C2.04919 14.2571 1.74323 13.9511 1.55148 13.5748C1.3335 13.147 1.3335 12.5869 1.3335 11.4668V10.3335H2.3335C3.25397 10.3335 4.00016 9.5873 4.00016 8.66683C4.00016 7.74636 3.25397 7.00016 2.3335 7.00016H1.3335C1.3335 6.06828 1.3335 5.60234 1.48574 5.2348C1.68872 4.74474 2.07807 4.35539 2.56813 4.1524C2.93567 4.00016 3.40161 4.00016 4.3335 4.00016H5.00016V3.00016Z"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3112_11949">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
