import { SvgIcon } from "../../svg";
import { AllBusiness, isAllBusiness } from "../../api";
import { Business } from "@norma-bi/bi-api";
import { useOnboardingProgressStore } from "../../stores/OnboardingProgressStore";
import { useGetBusinesses } from "../../hooks/useGetBusinesses";
import { Loader } from "../Loader";
import { useBusinessContext } from "../../hooks/useBusinessContext";
import { useIsHovered } from "../../utils/useIsHovered";
import { customToast } from "../../utils/customToast";
import { BusinessAvatar } from "./BusinessAvatar";

export type BusinessPickerProps = {
  businesses: ReturnType<typeof useGetBusinesses>;
};

export function BusinessPicker(props: BusinessPickerProps) {
  const businessContext = useBusinessContext();

  const onboardingProgressStore = useOnboardingProgressStore();

  async function onSelectBusiness(business: Business) {
    try {
      await businessContext.setBusiness(business.id);
      customToast("Business changed successfully", "success", "bottom");
    } catch (e) {
      console.error("could not change business context:", e);
    }
  }

  if (!onboardingProgressStore.isHydrated) {
    return <Loader />;
  }

  const AllBusinessesEntry = () => {
    return (
      <BusinessEntry
        business={AllBusiness}
        isPending={!onboardingProgressStore.allHaveFinished}
        isSelected={
          Boolean(businessContext.business) && isAllBusiness(businessContext.business!.id)
        }
        onSelect={() => onSelectBusiness(AllBusiness)}
        iconClassName={"bg-cyan"}
      />
    );
  };

  return (
    <div className="flex flex-col">
      {!props.businesses.isSuccess ? (
        <Loader />
      ) : (
        <>
          {props.businesses.data.length > 1 && <AllBusinessesEntry />}

          {props.businesses.data.map((b) => {
            const isSelected = businessContext.business?.id === b.id;
            return (
              <BusinessEntry
                key={b.id}
                business={b}
                onSelect={() => onSelectBusiness(b)}
                isSelected={isSelected}
                isPending={!onboardingProgressStore.businessHasFinished(b.id)}
                iconClassName={"bg-primaryColor"}
              />
            );
          })}
        </>
      )}
    </div>
  );
}

type BusinessEntryProps = {
  business: Business;
  onSelect: () => void;
  isSelected: boolean;
  isPending: boolean;
  iconClassName?: string;
};

function BusinessEntry(props: BusinessEntryProps) {
  const { props: buttonProps, isHovered } = useIsHovered<HTMLButtonElement>();

  function onClick() {
    if (props.isSelected) {
      return;
    }
    if (props.isPending) {
      customToast(
        "We haven't retrieved the data of this Business, yet. This may take up to 30 minutes.",
        "success",
        "bottom",
      );
      return;
    }
    props.onSelect();
  }

  const isSelectable = !props.isPending && !props.isSelected;

  return (
    <button
      {...buttonProps}
      className={`flex items-center p-4 gap-2 hover:bg-backgroundGray select-none w-full ${!isSelectable ? "cursor-not-allowed" : ""}`}
      onClick={onClick}
    >
      <SvgIcon icon="More" />

      <BusinessAvatar
        businessDisplayName={props.business.display_name}
        className={props.iconClassName}
      />

      <div className="flex justify-between items-center flex-1">
        <div className="flex flex-col text-sm text-left">
          <span className="content-label">{props.business.display_name}</span>
          {props.isPending && <span className="text-xs text-feintGray font-normal">Pending</span>}
        </div>

        {props.isSelected && (
          <div className="text-success">
            <SvgIcon icon="Check" />
          </div>
        )}

        {isHovered && isSelectable && (
          <div className={"text-dark"}>
            <SvgIcon icon={"Check"} />
          </div>
        )}

        {isHovered && props.isPending && (
          <div className={"text-dark"}>
            <SvgIcon icon={"Cross"} />
          </div>
        )}
      </div>
    </button>
  );
}
