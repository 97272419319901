import { SubmitHandler, useForm } from "react-hook-form";
import { Card } from "../../components/Card";
import { isEmailValid, isAccountNumberValid } from "@norma-bi/utils";
import { useSearchParams } from "react-router";

export type ForgotPasswordFormProps = {
  onConfirm: (accountNumber: string, email: string) => void;
  onCancel: () => void;
  isPending: boolean;
  cardClassname?: string;
};

export function ForgotPasswordForm(props: ForgotPasswordFormProps) {
  const [searchParams] = useSearchParams();
  const accountNumber = searchParams.get("accountNumber");
  const email = searchParams.get("email");

  type ForgotPasswordDetails = {
    accountNumber: string;
    email: string;
  };

  const { register, handleSubmit, watch } = useForm<ForgotPasswordDetails>({
    defaultValues: {
      accountNumber: accountNumber ?? "",
      email: email ?? "",
    },
  });

  const canSubmit = isAccountNumberValid(watch("accountNumber")) && isEmailValid(watch("email"));

  const onSubmit: SubmitHandler<ForgotPasswordDetails> = async (data) => {
    props.onConfirm(data.accountNumber, data.email);
  };

  return (
    <Card
      className={props.cardClassname}
      cardTitle={"Reset BI Password"}
      Footer={
        <>
          <input
            type="button"
            value={"Cancel"}
            className="thoughtspot-cancel-button"
            form="forgot-password-form"
            onClick={props.onCancel}
          />
          <input
            disabled={!canSubmit || props.isPending}
            type="submit"
            className="thoughtspot-main-button"
            value={props.isPending ? "Loading..." : "Reset"}
            form="forgot-password-form"
          />
        </>
      }
    >
      <form id="forgot-password-form" className="py-10" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-8">
          <div className="thoughtspot-text-input-field">
            <label className="w-[30%]" htmlFor="accountNumber">
              Account No
            </label>
            <input
              id="accountNumber"
              className="w-[55%] max-w-[660px]"
              type="text"
              {...register("accountNumber")}
              placeholder="Type your Account No"
            />
          </div>
          <div className="thoughtspot-text-input-field">
            <label className="w-[30%]" htmlFor="email">
              Email
            </label>
            <input
              id="email"
              className="w-[55%] max-w-[660px]"
              type="text"
              {...register("email")}
              placeholder="Type your email"
            />
          </div>
        </div>
      </form>
    </Card>
  );
}
