import { ChangeModeRequestModeEnum as AppMode } from "@norma-bi/bi-api";

export type AppModeToggleProps = {
  mode: AppMode;
  onToggleMode: () => void;
};

export function AppModeToggle(props: AppModeToggleProps) {
  const displayText = props.mode === AppMode.lite ? "Pro mode" : "Lite mode";

  return (
    <button className="cursor-pointer hover:text-primaryColor" onClick={props.onToggleMode}>
      {displayText}
    </button>
  );
}
