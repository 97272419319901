import React from "react";
import { RequestResetPasswordResponseResultEnum } from "@norma-bi/bi-api";
import { Card } from "../../components/Card";

export type ResetPasswordResultPromptProps = {
  result: RequestResetPasswordResponseResultEnum;
  onFinish: () => void;
  cardClassname?: string;
};

export function ResetPasswordResultPrompt(props: ResetPasswordResultPromptProps) {
  const messages = React.useMemo<{ title: React.ReactNode; message: React.ReactNode }>(() => {
    switch (props.result) {
      case "user-not-found-in-business":
        return {
          title: "User not found",
          message:
            "This email could not be found in the specified business. Try another email or create a user with this email in the Foodics console",
        };
      case "business-not-found":
        return {
          title: "Business not found",
          message: (
            <>
              This business does not seem to have the BI installed. Press the{" "}
              <b>
                <i>Install</i>
              </b>{" "}
              button and use an owner's credentials to complete the installation
            </>
          ),
        };
      case "user-has-no-branches":
        return {
          title: "User has no branches",
          message:
            "This user has 0 branches in the console. Add branches to this user and try again.",
        };
      case "ok":
      case "onboarded-jit":
      default:
        return {
          title: "Check your email",
          message: `You will shortly receive an email with a link to activate your account or reset your password`,
        };
    }
  }, [props.result]);

  return (
    <Card
      className={props.cardClassname}
      cardTitle={messages.title}
      Footer={
        <>
          <input
            type="button"
            className="thoughtspot-main-button"
            value={"Finish"}
            onClick={props.onFinish}
          />
        </>
      }
    >
      <div className="flex flex-col py-4">
        <span className="content-label-subhead">{messages.message}</span>
      </div>
    </Card>
  );
}
