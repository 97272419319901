import React from "react";
import { useLocation } from "react-router";
import { LiveboardEmbed, Action } from "@thoughtspot/visual-embed-sdk/react";
import { ExpandVisualization } from "../../components/ExpandVisualization";
import { useExpandVisualizationStore } from "../../stores/useExpandVisualizationStore";
import { COMMON_HIDDEN_ACTIONS, EXPAND_ACTION } from "../../thoughtspot/constants";
import { useCaptureLiveboardView } from "../../hooks/useCaptureLiveboardView";
import { useThoughtSpotEmbedCommonProps } from "../../thoughtspot";

interface OnCustomActionArgs {
  data: {
    id: string;
    embedAnswerData: {
      vizId: string;
    };
  };
}

const hiddenActions: Action[] = [
  ...COMMON_HIDDEN_ACTIONS,
  Action.Save,
  Action.CopyAndEdit,
  Action.Schedule,
  Action.SchedulesList,
  Action.EditTitle,
  Action.RenameModalTitleDescription,
  Action.Pin,
  Action.SpotIQAnalyze,
  Action.Explore,
  Action.AddToFavorites,
];

export function Liveboard() {
  const location = useLocation();
  const expandViz = useExpandVisualizationStore((state) => state.show);

  const match = location.pathname.match(/\/pinboard\/([^/]+)/);
  const liveboardId = match ? match[1] : null;

  const handleCustomAction = React.useCallback(
    ({
      data: {
        id,
        embedAnswerData: { vizId },
      },
    }: OnCustomActionArgs) => {
      if (!liveboardId) {
        return;
      }
      if (id.toLowerCase() === EXPAND_ACTION.toLowerCase()) {
        expandViz({
          liveboardId,
          vizId,
        });
      }
    },
    [expandViz, liveboardId],
  );

  /****** LIVEBOARD VIEW CAPTURING ******/

  const lastLiveboardId = React.useRef<string | null>(null);
  const captureLiveboardView = useCaptureLiveboardView();
  React.useEffect(() => {
    const liveboardIdDidNotChange = liveboardId === lastLiveboardId.current;

    lastLiveboardId.current = liveboardId;

    if (!liveboardId || liveboardIdDidNotChange) {
      return;
    }
    captureLiveboardView(liveboardId);
  }, [liveboardId, captureLiveboardView]);

  /**************************************/

  const commonProps = useThoughtSpotEmbedCommonProps();

  if (!liveboardId) {
    return null;
  }

  return (
    <>
      <LiveboardEmbed
        {...commonProps}
        className="w-full h-full"
        liveboardId={liveboardId}
        showLiveboardTitle={true}
        showLiveboardDescription={true}
        hiddenActions={hiddenActions}
        onCustomAction={handleCustomAction}
      />
      <ExpandVisualization />
    </>
  );
}
