import React from "react";
import { SvgIcon } from "../../svg";
import clsx from "clsx";

export type FavoriteToggleProps = {
  showFavoritesOnly: boolean;
  setShowFavoritesOnly: React.Dispatch<React.SetStateAction<boolean>>;
};

export const FavoriteToggle = ({
  showFavoritesOnly,
  setShowFavoritesOnly,
}: FavoriteToggleProps) => {
  return (
    <button className={clsx("h-full w-full")} onClick={() => setShowFavoritesOnly((curr) => !curr)}>
      <div
        className={clsx(
          "h-full w-full rounded-lg flex items-center justify-center",
          showFavoritesOnly
            ? "border-2 border-sematicYellow text-sematicYellow bg-white shadow-button"
            : "bg-white text-feintGray hover:text-dark shadow-button",
        )}
      >
        <SvgIcon icon={"FavoriteEmpty"} />
      </div>
    </button>
  );
};
