import { useAPI } from "../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { LiveboardsQueryKey } from "../../hooks/useLiveboards";

export function useFavoriteLiveboard() {
  const api = useAPI();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (args: { liveboardId: string; isFavorite: boolean }) => {
      if (args.isFavorite) {
        return api.unfavoriteThoughtspotLiveboard({ liveboard_id: args.liveboardId });
      }
      return api.favoriteThoughtspotLiveboard({ liveboard_id: args.liveboardId });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [LiveboardsQueryKey] });
    },
  });
}
